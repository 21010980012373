import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [data, setData] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentResult, setCurrentResult] = useState(null);
  const [currentImage, setCurrentImage] = useState('');
  const [result, setResult] = useState(null);
  const [language, setLanguage] = useState(navigator.language.slice(0, 2));

  useEffect(() => {
    fetch('flow.json')
      .then(response => response.json())
      .then(data => {
        setData(data);
        setCurrentQuestion(data.questions[0]);
      });
  }, []);

  const handleOptionClick = option => {
    if ('nextQuestion' in option) {
      const nextQuestion = data.questions.find(q => q.id === option.nextQuestion);
      setCurrentQuestion(nextQuestion);
      setCurrentImage(option.image);
    } else if ('result' in option) {
      const result = data.results.find(r => r.id === option.result);
      setResult(result);
      setCurrentQuestion(null);
    }
  };

// handle language change
  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  fetch('flow.json')
    .then(response => response.json())
    .then(data => {
      setData(data);
      setCurrentQuestion(data.questions[0]);
      setIsLoading(false);  // Set isLoading to false when the data has been loaded
    });
}, []);

// In your component rendering logic, check if the data is still loading
if (isLoading) {
  return <div>Loading...</div>;  // You can replace this with a loading spinner or any other loading indicator
}


const handleAnswerClick = (nextId) => {
  const nextQuestion = data.questions.find((question) => question.id === nextId);
  const nextResult = data.results.find((result) => result.id === nextId);

  if (nextQuestion) {
    setCurrentQuestion(nextQuestion);
  } else if (nextResult) {
    setCurrentResult(nextResult);
  }
};

return (
<div className="app">
  <div className="language-selector">
    {Object.keys(data.questions[0].text).map((lang) => (
      <button
        key={lang}
        className={`language-button ${lang === language ? 'active' : ''}`}
        onClick={() => handleLanguageChange(lang)}
      >
        {lang.toUpperCase()}
      </button>
    ))}
  </div>

  <div className="main">
    {result ? (
      <div className="result">
        <p>{result.text[language]}</p>
        <a href={result.link} className="result-link">Go to result</a>
      </div>
    ) : (
      <React.Fragment>
        <div className="left">
          <h1 dangerouslySetInnerHTML={{ __html: currentQuestion.text[language] }} />
          {currentQuestion.options.map((option, index) => (
            <button key={index} onClick={() => handleOptionClick(option)}>
              {option.text[language]}
            </button>
          ))}
        </div>
        <div className="right">
          <img src={currentImage} alt="Current option" />
        </div>
      </React.Fragment>
    )}
  </div>
</div>
);

}

export default App;

